import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {
  fetchCountriesCatalog,
  fetchFactoriesCatalog,
  fetchOfficesCatalog,
  fetchRolesCatalog,
  getCountriesCatalog,
  getFactoriesCatalog,
  getOfficesCatalog,
  getRolesCatalog,
} from "../../slices/GeneralCatalogSlice";
import {deselectKit, deselectSample} from "../../slices/SampleFunctionalSlice";
import {fetchAllGroups, fetchAllMaterials, fetchAllTextures} from "../../slices/SampleSlice/operations";
import {setModalData} from "../../slices/SampleSlice/SampleSlice";
import {selectGroups, selectMaterials, selectTextures} from "../../slices/SampleSlice/selectors";
import {getAllUsers, getCurrentUser, getUsers} from "../../slices/UserSlice";
import DynamicPageContainer from "../DynamicPageContainer/DynamicPageContainer";

import KitSampleCreateModal from "./SampleModals/KitSampleCreateModal";
import KitSampleUpdateModal from "./SampleModals/KitSampleUpdateModal";
import SampleCreateModal from "./SampleModals/SampleCreateModal";
import SampleUpdateModal from "./SampleModals/SampleUpdateModal";
import ViewKitTab from "./SampleModals/ViewKitTab";
import ViewSampleTab from "./SampleModals/ViewSampleTab";
import LowerSampleBlock from "./SampleParts/LowerSampleBlock";
import SampleFilter from "./SampleParts/SampleFilter/SampleFilter";
import SampleFuncButtons from "./SampleParts/SampleFuncButtons";
import UpperSampleBlock from "./SampleParts/UpperSampleBlock";
import {SAMPLE_UNI_MOD_INITIAL_VALUES} from "./SampleUniModal/SampleUniMod";

export const TYPE_FORMS = Object.freeze({
  CREATE_KIT: "CREATE_KIT",
  UPDATE_KIT: "UPDATE_KIT",
  VIEW_KIT: "VIEW_KIT",
  CREATE_KIT_SAMPLES: "CREATE_KIT_SAMPLES",
  EDIT_KIT_SAMPLES: "EDIT_KIT_SAMPLES",
  VIEW_KIT_SAMPLES: "VIEW_KIT_SAMPLES",
  DUBLICATE_KIT: "  DUBLICATE_KIT",

  CREATE_SAMPLE: "CREATE_SAMPLE",
  EDIT_SAMPLE: "EDIT_SAMPLE",
  VIEW_SAMPLE: "VIEW_SAMPLE",
  TRANSFER_SAMPLE_TO_KIT: "TRANSFER_SAMPLE_TO_KIT",
  VIEW_KIT_SAMPLE: "VIEW_KIT_SAMPLE",
  CREATE_KIT_SAMPLE: "CREATE_KIT_SAMPLE",
  UPDATE_KIT_SAMPLE: "UPDATE_KIT_SAMPLE",
  DUBLICATE_KIT_SAMPLE: "  DUBLICATE_KIT_SAMPLE",
  DUBLICATE_SAMPLE: "DUBLICATE_SAMPLE",
});

const SamplesListBase = ({setTabs, setTabValue}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currUser = useSelector(getCurrentUser);

  const textures = useSelector(selectTextures);
  const groups = useSelector(selectGroups);
  const materials = useSelector(selectMaterials);
  const offices = useSelector(getOfficesCatalog);
  const roles = useSelector(getRolesCatalog);
  const countries = useSelector(getCountriesCatalog);
  const factories = useSelector(getFactoriesCatalog);
  const users = useSelector(getUsers);

  useEffect(() => {
    if (
      !offices?.length ||
      !roles?.length ||
      !factories?.length ||
      !countries?.length ||
      !users?.length ||
      !groups?.length ||
      !materials?.length ||
      !textures?.length
    ) {
      dispatch(fetchOfficesCatalog());
      dispatch(fetchRolesCatalog());
      dispatch(fetchCountriesCatalog());
      dispatch(fetchFactoriesCatalog());
      dispatch(getAllUsers());
      dispatch(fetchAllGroups());
      dispatch(fetchAllMaterials());
      dispatch(fetchAllTextures());
    }
  }, []);

  // const [showModal, setShowModal] = useState(false);

  const [formType, setFormType] = useState(null);
  const [tabProps, setTabProps] = useState({});
  const [tabLabel, setTabLabel] = useState("");
  const [currentSample, setCurrentSample] = useState(null);

  const tabComponent = useMemo(() => {
    switch (formType) {
      case TYPE_FORMS.CREATE_INSTANCE:
        return SampleCreateModal;
      case TYPE_FORMS.UPDATE_INSTANCE:
        return SampleUpdateModal;
      case TYPE_FORMS.CREATE_KIT:
        return KitSampleCreateModal;
      case TYPE_FORMS.UPDATE_KIT:
        return KitSampleUpdateModal;
      case TYPE_FORMS.VIEW_KIT:
        return ViewKitTab;
      case TYPE_FORMS.VIEW_INSTANCE:
        return ViewSampleTab;
      default:
        null;
    }
  }, [formType]);

  const label = useMemo(() => {
    switch (formType) {
      case TYPE_FORMS.CREATE_INSTANCE:
        return "create s";
      case TYPE_FORMS.UPDATE_INSTANCE:
        return "edit s";
      case TYPE_FORMS.CREATE_KIT:
        return "create k";
      case TYPE_FORMS.UPDATE_KIT:
        return "edit k";
      case TYPE_FORMS.VIEW_KIT:
        return "view k";
      case TYPE_FORMS.VIEW_INSTANCE:
        return "view s";
    }
  }, [formType]);

  useEffect(() => {
    if (tabComponent) {
      setTabValue(prev => ++prev);
      setTabs(prev => {
        setTabValue(prev.length);
        return [
          ...prev,
          {
            Component: tabComponent,
            label: tabLabel ? t(tabLabel) : t(label),
            props: {setTabs, typeForm: formType, ...tabProps},
          },
        ];
      });
    }
  }, [tabComponent, label]);

  return (
    <div className="form_wrapper hmax100 fGrow" style={{justifyContent: "flex-start"}}>
      <div>
        <SampleFilter />
        <div className="border border_gap"></div>
        <div>
          <UpperSampleBlock
            setShowModal={() => {}}
            setFormType={setFormType}
            formType={formType}
            setTabValue={setTabValue}
            setTabs={setTabs}
            setTabProps={setTabProps}
            setTabLabel={setTabLabel}
            setCurrentSample={setCurrentSample}
          />
          <div className="border border_gap mt mb"></div>
        </div>
      </div>
      <LowerSampleBlock
        setShowModal={() => {}}
        setFormType={setFormType}
        formType={formType}
        setTabValue={setTabValue}
        setTabs={setTabs}
        setTabProps={setTabProps}
        setTabLabel={setTabLabel}
        currentSample={currentSample}
        setCurrentSample={setCurrentSample}
      />
      {currUser?.role?.roleId === 3 && (
        <div>
          <div className="border border_gap"></div>
          <SampleFuncButtons />
        </div>
      )}
    </div>
  );
};

const SamplesList = () => {
  const dispatch = useDispatch();

  function onCloseTab(tab) {
    const typeForm = tab.props.typeForm;
    switch (typeForm) {
      case TYPE_FORMS.UPDATE_KIT:
        dispatch(deselectKit());
        break;
      case TYPE_FORMS.UPDATE_INSTANCE:
        dispatch(deselectSample());
        break;
    }
    setTimeout(() => {
      dispatch(setModalData({typeForm, formData: SAMPLE_UNI_MOD_INITIAL_VALUES}));
    }, []);
  }

  return <DynamicPageContainer title="kit reg" Component={SamplesListBase} onCloseTab={onCloseTab} />;
};

export default SamplesList;
