import {toast} from "react-toastify";
import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import Swal from "sweetalert2";

import {fetchData, fetchList} from "../api/axios.hook";

// import store from "../store";
// import { instance } from "../api/apiSetup";

const token = localStorage.getItem("access_token");

export const getError = error => {
  toast.error(error);
};
export const getInfo = text => {
  toast.info(text);
};
export const getSuccess = text => {
  toast.success(text);
};

const initialState = {
  user: null,
  isAuth: false,
  authLoadingStatus: "idle",
  token: token ? token : null,
  facsimile: null,
  systemLanguage: "en",
  allUsers: [],
  userFactories: null,
  pageTabs: [],
  curentTabIndex: 0,
  // chosenUser: null,
};

export const login = createAsyncThunk("user/login", async body => {
  const responce = fetchData("/auth/login/", "post", body);
  return responce;
});

export const logout = createAsyncThunk("user/logout", async () => {
  const responce = fetchData("/auth/logout", "post");
  return responce;
});

export const changePassword = createAsyncThunk("user/changePassword", async ({userId, password}) => {
  const responce = fetchData(`/users/changePass/${userId}`, "patch", {
    password: password,
  });
  return responce;
});

export const addNewUser = createAsyncThunk("users/addNewUser", async ({user, roleId, officeId}) => {
  const responce = fetchData(`/users/add/${roleId}/${officeId}`, "post", user);
  return responce;
});

export const deleteUser = createAsyncThunk("users/deleteUser", async userId => {
  const response = fetchData(`users/remove/${userId}`, "delete");
  return response;
});

export const getUser = createAsyncThunk("user/getUser", async () => {
  const responce = fetchData("/users/getCurrentUser", "get");
  return responce;
});

export const getAllUsers = createAsyncThunk("users/getAllUsers", async () => {
  const responce = fetchData("/users/getAll", "get");
  return responce;
});

//*** User Factories ***
export const getFactoriesForUser = createAsyncThunk("users/getFactoriesForUser", async userId =>
  fetchList(`/users/getFactories/${userId}`, "get"),
);
export const addFactoryToUser = createAsyncThunk("users/addFactoryToUser", async ({userId, factoryId}) =>
  fetchData(`/users/addFactory/${userId}/${factoryId}`, "post"),
);
export const deleteFactoryFromUser = createAsyncThunk("users/deleteFactoryFromUser", async ({userId, factoryId}) =>
  fetchData(`/users/removeFactory/${userId}/${factoryId}`, "delete"),
);
// export const createFacsimile = createAsyncThunk("user/createFacsimile", async formData => {
//   const responce = fetchData("/facsimile/create", "post", formData);
//   return responce;
// });

// export const getFacsimileForCurrentUser = createAsyncThunk("user/getFacsimileForCurrentUser", async () => {
//   const responce = fetchData("/facsimile/getForCurrent", "get");
//   return responce;
// });

export const registrUser = async user => {
  fetchData("/users/add", "post", user).then(res => {
    if (Object.prototype.hasOwnProperty.call(res, "persona")) {
      // getInfo(`Користувача ${res.persona.CA_FULL_NAME} успішно зареєстровано`);
      Swal.fire({
        title: `Користувача ${res.persona.DA_EMPLOYEE_NAME} успішно зареєстровано`,
        text: `Логін: ${res.persona.DA_LOGIN}`,
        icon: "info",
        confirmButtonText: "Ок",
      });
    }
  });
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setNotAuth: state => {
      localStorage.removeItem("access_token");
      state.token = null;
      state.user = null;
    },
    addChosenUser: (state, payload) => {
      state.chosenUser = payload;
    },
    addFacsimile: (state, {payload}) => {
      state.facsimile = payload;
    },
    setSystemLanguage: (state, {payload}) => {
      state.systemLanguage = payload;
    },
    cleanUserFactories: state => {
      state.userFactories = null;
    },
    setPageTabs: (state, {payload}) => {
      state.pageTabs = payload;
    },
    updatePageTabProps: (state, {payload}) => {
      const {pageType, props} = payload;
      let index = 0;
      state.pageTabs = state.pageTabs.map((tab, i) => {
        if (tab.formType === pageType) {
          index = i;
          return {...tab, label: payload?.label || tab.label, props: {...tab.props, ...props}};
        }
        return tab;
      });
      state.curentTabIndex = index + 1;
    },
    setCurentTabIndex: (state, {payload}) => {
      state.curentTabIndex = payload;
    },
    addPageTab: (state, {payload}) => {
      state.pageTabs.push(payload);
      state.curentTabIndex = state.pageTabs.length;
    },
    closeCurrentTab: state => {
      const prevTabs = [...(state.pageTabs ?? [])];
      console.log("prevTabs :", prevTabs);
      const newTabs = prevTabs.splice(state.curentTabIndex, 1);
      console.log("newTabs :", newTabs);
      console.log("state.curentTabIndex :", state.curentTabIndex);

      state.pageTabs = newTabs;
      state.curentTabIndex = state.curentTabIndex !== 0 ? state.curentTabIndex - 1 : 0;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, {payload}) => {
        if (Object.prototype.hasOwnProperty.call(payload, "message")) {
          getInfo(payload.message);
          state.user = payload.userId;
          state.token = null;
          localStorage.removeItem("access_token");
        }
        if (Object.prototype.hasOwnProperty.call(payload, "token")) {
          state.token = payload.token;
          state.user = payload.user;
          localStorage.setItem("access_token", payload.token);
        }
      })
      .addCase(changePassword.fulfilled, (state, {payload}) => {
        state.token = payload.token;
        state.user = payload.user;
        localStorage.setItem("access_token", payload.token);
        getInfo("Пароль успішно змінено!");
      })
      .addCase(logout.fulfilled, state => {
        localStorage.removeItem("access_token");
        state.token = null;
        state.user = null;
      })
      .addCase(getUser.fulfilled, (state, {payload}) => {
        state.user = payload;
      })
      .addCase(addNewUser.fulfilled, (state, {payload}) => {
        const transRole =
          payload.roleId === 1 ? {roleId: 1, roleName: "Brand Manager"} : {roleId: 2, roleName: "Manager"};
        state.allUsers = [...state.allUsers, {...payload, role: transRole}];
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.allUsers = state.allUsers.filter(req => req.userId !== action.meta.arg);
      })
      .addCase(getAllUsers.fulfilled, (state, {payload}) => {
        state.allUsers = payload;
      })
      // .addCase(createFacsimile.fulfilled, (state, {payload}) => {
      //   state.facsimile = payload;
      // })
      // .addCase(getFacsimileForCurrentUser.fulfilled, (state, {payload}) => {
      //   state.facsimile = payload;
      // })
      .addCase(getUser.rejected, state => {
        state.token = null;
        state.user = null;
      })
      .addCase(getFactoriesForUser.fulfilled, (state, {payload}) => {
        state.userFactories = payload;
      })
      .addCase(addFactoryToUser.fulfilled, (state, {payload}) => {
        state.userFactories = payload;
      })
      .addCase(deleteFactoryFromUser.fulfilled, (state, {payload}) => {
        state.userFactories = payload;
      })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = userSlice;

export const {
  setNotAuth,
  addFacsimile,
  setSystemLanguage,
  cleanUserFactories,
  setPageTabs,
  addPageTab,
  setCurentTabIndex,
  updatePageTabProps,
  closeCurrentTab,
} = actions;

export default reducer;

export const getCurrentUser = state => state.user.user;
export const getUsers = state => state.user.allUsers;
export const selectBrandManagers = createSelector([getUsers], allUsers =>
  allUsers.filter(user => user.role.roleName === "Brand Manager"),
);
export const selectManagers = createSelector([getUsers], allUsers =>
  allUsers.filter(user => user.role.roleName === "Manager"),
);

export const selectFacsimile = state => state.user.facsimile;
export const selectUserFactories = state => state.user.userFactories;

export const selectPageTabs = state => state.user.pageTabs;
export const selectPageTabIndex = state => state.user.curentTabIndex;
