import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import useColumns from "../../../hook/useColumns";
import useSelectOptions from "../../../hook/useSelectOptions";
import {selectAllSamples} from "../../../slices/SampleFunctionalSlice";
import {addNewUser, deleteUser, getCurrentUser, getError, getMngUser, getUsers} from "../../../slices/UserSlice";
import {BUTTON_TYPES} from "../../../utilities/Buttons/constants";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ReactTable from "../../conteiners/ReactTable";
import {TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import {ManagerRegistrColumns} from "../constants/columns";
import mngRegistrTableDataService from "../constants/mngRegistrTableDataService";

import "./mngRegistr.scss";

const SampleRegistrManager = ({setModalActive}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const columns = useColumns(ManagerRegistrColumns);

  const {officesOptions, rolesOptions} = useSelectOptions();

  const allUsers = useSelector(getUsers),
    currUser = useSelector(getCurrentUser),
    allSamples = useSelector(selectAllSamples);

  const [currentMngUser, setCurrentMngUser] = useState(null);

  const mngTableData = mngRegistrTableDataService(allUsers);

  const initialValues = {
    brandManager: currUser?.lastName + " " + currUser?.firstName,
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
    officeId: "",
  };

  const handleSubmit = (values, {resetForm}) => {
    dispatch(
      addNewUser({
        user: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
        },
        roleId: values.roleId,
        officeId: values.officeId,
      }),
    ).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        resetForm();
      }
    });
  };

  const onDeleteUser = () => {
    const isBM = allSamples.find(item => item?.brandManager?.userId === currentMngUser?.userId);
    const isUnderReportManager = allSamples.find(item => item?.manager?.userId === currentMngUser?.userId);
    const isSuperuser = allUsers.find(item => item.role.roleId === 3);

    Swal.fire({
      title: "",
      text: t("del inf"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        if (currentMngUser.userId === currUser.userId) {
          Swal.fire({
            title: "",
            text: t("curr mng del"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          return;
        } else if (isBM) {
          console.log("isBM", isBM);
          Swal.fire({
            title: "",
            text: t("samp brand mng enable"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
        } else if (isUnderReportManager) {
          Swal.fire({
            title: "",
            text: t("und repor unable"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
        } else if (isSuperuser) {
          Swal.fire({
            title: "",
            text: t("super user del"),
            icon: "info",
            confirmButtonText: t("Ok"),
            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
        } else {
          dispatch(deleteUser(currentMngUser.userId));
          setCurrentMngUser(null);
        }
      }
    });
  };

  const onSelectUser = id => {
    if (currentMngUser && id === currentMngUser.userId) {
      setCurrentMngUser(null);
      return;
    }
    const currUser = allUsers.find(user => user.userId === id);
    setCurrentMngUser(currUser);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      // .required(t("ent name"))
      .matches(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ]+$/, t("n inf")),
    lastName: Yup.string()
      .required(t("ent ln"))
      .matches(/^[a-zA-Zа-яА-ЯІіЇїЄєҐґ]+$/, t("ln inf")),
    email: Yup.string().required(t("ent em")).email(t("em valid")),
    roleId: Yup.number().required(t("ch r")),
    officeId: Yup.string().required(t("ch o")),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({values}) => (
        <Form style={{paddingTop: "0.5em"}}>
          <TextInput
            label={t("BM")}
            name="brandManager"
            width="15em"
            labelStyles={{width: "10em"}}
            generalClassName="brand_mng"
          />
          <div className="main_registr_fields">
            <TextInput label={t("Last Name") + "*"} name="lastName" labelStyles={{width: "8em"}} width="17em" />
            <TextInput label={t("Name")} name="firstName" labelStyles={{width: "8em"}} width="17em" />
            <TextInput label="e-mail*" name="email" labelStyles={{width: "8em"}} width="17em" />

            <Select label={t("role") + "*"} name="roleId" selectOnly={true} options={rolesOptions} width="18em" />
            <Select label={t("Offic") + "*"} name="officeId" selectOnly={true} options={officesOptions} width="18em" />
          </div>
          <ReactTable
            defaultData={mngTableData}
            columns={columns}
            onSelect={onSelectUser}
            current={currentMngUser}
            className={"scrollX"}
            style={{height: "20em"}}
          />
          <div className="buttons_mng_reg">
            <FunctionButton name="Del" onClick={onDeleteUser} disabled={!currentMngUser} />
            <div className="btns_t">
              <FunctionButton name="Save" type={BUTTON_TYPES.SUBMIT} />
              <FunctionButton
                name="Cancel"
                onClick={() => {
                  setModalActive(false);
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SampleRegistrManager;
