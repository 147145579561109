// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_size {
  width: 50vw;
}
@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .modal_size {
    width: 65vw;
  }
}

.m_conf {
  min-width: initial;
}

.brand_mng {
  display: flex;
  justify-content: end;
}

.main_registr_fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  gap: 0.6em;
}

.selects {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.buttons_mng_reg {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btns_t {
  display: flex;
  flex-direction: row;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SamplesList/SampleModals/mngRegistr.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EAFF;IAGI,WAAA;EAGF;AACF;;AAAA;EACE,kBAAA;AAGF;;AAAA;EACE,aAAA;EACA,oBAAA;AAGF;;AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,UAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AAGF;;AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGF","sourcesContent":[".modal_size {\n  width: 50vw;\n  @media screen and (min-width: 1024px) and (max-width: 1439px) {\n    width: 65vw;\n  }\n}\n\n.m_conf {\n  min-width: initial;\n}\n\n.brand_mng {\n  display: flex;\n  justify-content: end;\n}\n\n.main_registr_fields {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 20px;\n  gap: 0.6em;\n}\n\n.selects {\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n}\n\n.buttons_mng_reg {\n  margin-top: 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.btns_t {\n  display: flex;\n  flex-direction: row;\n  gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
