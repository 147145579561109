import {useDispatch, useSelector} from "react-redux";

import {getAllKits} from "../../slices/SampleFunctionalSlice";
import {setCurrentKitId} from "../../slices/SampleSlice/SampleSlice";
import {getCurrentUser} from "../../slices/UserSlice";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";
import Divider from "../../utilities/Divider/Divider";
import useDynamicPageContainer from "../DynamicPageContainer/useDynamicPageContainer";
import withDynamicPageContainer from "../DynamicPageContainer/withDynamicPageContainer";
import SampleFilter from "../Filters/SampleFilter";
import SampleFuncButtons from "../SamplesList/SampleParts/SampleFuncButtons";

import LowerKitBlock from "./LowerKitBlock";
import UpperKitBlock from "./UpperKitBlock";

const KitsList = ({setTabs, setTabValue}) => {
  const {formType, setFormType, setTabProps, setTabLabel} = useDynamicPageContainer({setTabs, setTabValue});
  const dispatch = useDispatch();
  const currUser = useSelector(getCurrentUser);

  const fetchSamples = values => {
    dispatch(setCurrentKitId(null));
    if (!values) {
      dispatch(getAllKits());
      return;
    }
    const filters = checkEmptyFields(values);
    dispatch(getAllKits(filters));
  };

  return (
    <div className="form_wrapper hmax100 fGrow" style={{justifyContent: "flex-start"}}>
      <div>
        <SampleFilter
          fetchData={fetchSamples}
          removeFilter={() => {
            fetchSamples(null);
          }}
        />
        <Divider />
      </div>
      <div>
        <UpperKitBlock
          setShowModal={() => {}}
          setFormType={setFormType}
          formType={formType}
          setTabValue={setTabValue}
          setTabs={setTabs}
          setTabProps={setTabProps}
          setTabLabel={setTabLabel}
        />
        <div className="border border_gap mt mb"></div>
      </div>

      <LowerKitBlock
        setShowModal={() => {}}
        setFormType={setFormType}
        formType={formType}
        setTabValue={setTabValue}
        setTabs={setTabs}
        setTabProps={setTabProps}
        setTabLabel={setTabLabel}
      />

      {currUser?.role?.roleId === 3 && (
        <div>
          <Divider />
          <SampleFuncButtons />
        </div>
      )}
    </div>
  );
};

export default withDynamicPageContainer({Component: KitsList, title: "kit reg"});
