export const SampleListUpperColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 60,
  },
  {
    header: "date kit",
    accessorKey: "createdAt",
    size: 130,
    isSortable: true,
    sortType: "datetime",
  },
  // {
  //   header: "Factory",
  //   accessorKey: "factory",
  //   // size: 15,
  //   isSortable: true,
  // },
  {
    header: "kitSKU №",
    accessorKey: "skuNumber",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Photo",
    accessorKey: "kitPhoto",
    size: 60,
  },

  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
    isSortable: true,
  },
  {
    header: "BM",
    accessorKey: "brandManager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "acc m",
    accessorKey: "manager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    size: 100,
    isSortable: true,
  },

  {
    header: "Status*",
    accessorKey: "status",
    // size: 100,
    isSortable: true,
  },
];

export const SampleListLowerColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 60,
  },
  {
    header: "kitSKU №",
    accessorKey: "kit",
    // size: 100,
    isSortable: true,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 15,
  },
  {
    header: "col",
    accessorKey: "collection",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Color",
    accessorKey: "color",
    // size: 100,
    isSortable: true,
  },
  {
    header: "texture",
    accessorKey: "texture",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Smp g",
    accessorKey: "group",
    // size: 100,
    isSortable: true,
  },
  {
    header: "sgr",
    accessorKey: "subgroup",
    size: 100,
    isSortable: true,
  },
  {
    header: "material",
    accessorKey: "material",
    // size: 100,
    isSortable: true,
  },
  {
    header: "categ",
    accessorKey: "category",
    // size: 100,
    isSortable: true,
  },

  {
    header: "Photo",
    accessorKey: "samplePhoto",
    size: 70,
  },

  {
    header: "BM",
    accessorKey: "brandManager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "acc m",
    accessorKey: "manager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "state",
    // size: 100,
  },

  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    size: 100,
    isSortable: true,
  },
  {
    header: "Status*",
    accessorKey: "status",

    isSortable: true,
  },

  {
    header: "ds",
    accessorKey: "skuDate",
    size: 170,
    isSortable: true,
  },
];
export const ManagerRegistrColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 20,
  },
  {
    header: "reg date",
    accessorKey: "regDate",
    size: 70,
  },
  {
    header: "full name",
    accessorKey: "fullName",
    size: 120,
  },
  {
    header: "e-mail",
    accessorKey: "eMail",
    // size: 100,
  },
  {
    header: "role",
    accessorKey: "role",
    // size: 100,
  },
];

export const SampleUniModTableColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 45,
  },
  {
    header: "SKU №",
    accessorKey: "skuN",
    // size: 100,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 15,
  },
  {
    header: "col",
    accessorKey: "collection",
    // size: 100,
  },
  {
    header: "Color",
    accessorKey: "color",
    // size: 100,
  },
  {
    header: "texture",
    accessorKey: "texture",
    // size: 100,
  },
  {
    header: "Nomenclature",
    accessorKey: "nomen",
    // size: 100,
  },
  {
    header: "Smp g",
    accessorKey: "sampleGroup",
    // size: 100,
  },
  {
    header: "sgr",
    accessorKey: "subGroup",
    // size: 100,
  },

  {
    header: "material",
    accessorKey: "material",
    // size: 100,
  },
  {
    header: "categ",
    accessorKey: "category",
    // size: 100,
  },
  {
    header: "ds",
    accessorKey: "dateSku",
    // size: 100,
  },
];
export const sampleGroupColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "nom gs",
    accessorKey: "sampleGroup",
    // size: 15,
  },
];
export const subGroupColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "nom sg",
    accessorKey: "subGroup",
    // size: 15,
  },
];
export const materialColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "nom m",
    accessorKey: "material",
    // size: 15,
  },
];
export const categoryColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "nom cat",
    accessorKey: "category",
    // size: 15,
  },
];
export const textureColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "nom tex",
    accessorKey: "texture",
    // size: 15,
  },
];

export const BRAND_MANAGERS_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Brand Manager",
    accessorKey: "brandManager",
    // size: 15,
  },
]);

export const FACTORIES_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 15,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 15,
  },
]);
