import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useField, useFormikContext} from "formik";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import {KEYBOARD_CODES} from "../../utilities/keyboardCodes";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

export const Select = ({
  label,
  width = "100%",
  // styles = null,
  id,
  labelWrap,
  options, ///// должен быть вида [{value: ###, title: ### }, ....]
  makeEffect = null, /// функция (не обязательная) которая должна сработать когда происходит выбор из выпадающего списка
  question, ///// пояснение к полю
  // nomination = "",
  // multi = false, ///если передаём в массив выбора объекты с несколькими dependentField например {orgId, orgName, orgCode}
  minFilterLength = 3, //// минимальная длина строки для фильтрации поля
  errorField,
  widthLabel = "",
  disabled = false,
  selectOnly = false,
  inputClassName,
  generalClassName = "",
  onKeyDown = null,
  ...props
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(null);

  const inputRef = useRef(null);
  const optionsListRef = useRef();
  const optionsItemRef = useRef();
  const optionsListContainerRef = useRef();
  const optionsLength = filteredOptions.length;

  useEffect(() => {
    if (!showOptions) {
      setFocusedOptionIndex(null);
    }
  }, [showOptions]);

  function onOptionsListKeyDown(e) {
    if (showOptions) {
      const currentOptionsListRef = optionsListRef.current;
      const optionHeight = optionsItemRef.current?.clientHeight;
      const scrollBottomValue = optionHeight + 1;
      const scrollTopValue = optionHeight - 0.1652;

      switch (e.code) {
        case KEYBOARD_CODES.ENTER:
          if (focusedOptionIndex !== null) {
            handleSelectOption(filteredOptions[focusedOptionIndex]);
          }
          break;

        case KEYBOARD_CODES.ARROW_UP:
          if (focusedOptionIndex !== null) {
            setFocusedOptionIndex(prev => (prev !== 0 ? --prev : optionsLength - 1));
            currentOptionsListRef.scrollTop =
              focusedOptionIndex !== 0
                ? currentOptionsListRef.scrollTop - scrollTopValue
                : currentOptionsListRef.scrollHeight;
          } else {
            setFocusedOptionIndex(optionsLength - 1);
            currentOptionsListRef.scrollTop = currentOptionsListRef.scrollHeight;
          }
          break;

        case KEYBOARD_CODES.ARROW_DOWN:
          if (focusedOptionIndex !== null) {
            setFocusedOptionIndex(prev => (prev !== optionsLength - 1 ? ++prev : 0));
            currentOptionsListRef.scrollTop =
              focusedOptionIndex !== optionsLength - 1 ? currentOptionsListRef.scrollTop + scrollBottomValue : 0;
          } else {
            setFocusedOptionIndex(0);
            currentOptionsListRef.scrollTop = 0;
          }
          break;
      }
    } else if (onKeyDown) {
      onKeyDown(e);
    }
  }

  const [field, meta, helpers] = useField(props);
  const {value} = field;

  const {
    // setFieldValue, handleChange,
    initialValues,
    errors,
  } = useFormikContext();
  const [textValue, setTextValue] = useState(initialValues[props?.name] ?? "");
  const {t, i18n} = useTranslation();

  const {tooltipShown, onMouseEnter, onMouseLeave} = useInputsTooltip(field);
  //////////фильтрация

  const updateFilterQuery = filterStr => {
    if (filterStr && filterStr.length >= minFilterLength) {
      setFilter(filterStr);
    } else {
      setFilter("");
    }
  };

  const delayedFilter = debounce(updateFilterQuery, 500);
  const namingType = useMemo(() => (i18n.language === "en" ? "engName" : "name"), [i18n.language]);

  useEffect(() => {
    var filtered = options.filter(option => {
      if (!option?.title || option.title == "") {
        return false;
      }
      let words = option.title.split(" ");
      return words.some(word => word.toLowerCase().startsWith(filter.toLowerCase()));
    });
    setFilteredOptions(filtered);

    if (filter !== "" && !showOptions && filtered.length > 0 && filtered.length !== options.length) {
      openOptions();
    }
  }, [filter, options]);

  /////////////////////////
  const timeout = useRef();

  const handleSelectOption = option => {
    clearTimeout(timeout.current);
    setShowOptions(false);
    setFilter("");

    helpers.setValue(option.value);
    inputRef.current.focus();
    if (makeEffect) makeEffect(option.value);
    // setTextValue(option.title)
  };

  const optionList = useMemo(() => {
    return filteredOptions.map((option, i) => {
      var {title} = option;
      const isSelected = focusedOptionIndex !== null && filteredOptions[focusedOptionIndex]?.title === title;

      return (
        <li
          className={`select_option ${isSelected ? "selected_option" : ""}`}
          key={i}
          onClick={() => {
            handleSelectOption(option);
          }}
          ref={optionsItemRef}
        >
          {title.length > 32 ? title.slice(0, 32) + "..." : title}
        </li>
      );
    });
  }, [filteredOptions.length, focusedOptionIndex]);

  useEffect(() => {
    setShowOptions(false);
    setFilter("");
    // valueChange();
  }, [options]);

  const openOptions = () => {
    if (filteredOptions.length > 0 && !props.readOnly) setShowOptions(true);
  };

  useEffect(() => {
    showAllOptions();
  }, [initialValues]);

  const showAllOptions = useCallback(() => {
    setFilter("");
  }, []);

  //////// findId ////////

  const updateIdQuery = value => {
    if (value && value !== "") {
      var item = options.find(option => option.title === value);
      if (item) helpers.setValue(item.value);
      // else helpers.setValue(null); //////  helpers.setValue(value); (если ввести цифру и отправить воспримет как айди, а не как ошибку что такого значения нет в списке)
    } else {
      helpers.setValue(null);
    }
  };

  const valueChange = () => {
    if (value && value !== "") {
      if (value?.title) {
        setTextValue(value.title);
        updateIdQuery(value.title);
      } else if (options.length > 0) {
        const item = options.find(option => option.value === value);
        if (item) {
          setTextValue(item.title);
          updateIdQuery(item.title);
        }
      } else if (options?.length < 1) {
        return;
      }
    } else if (value === "" || !value) {
      setTextValue("");
      updateIdQuery("");
    }
  };

  useEffect(() => {
    valueChange();
  }, [value, options]);

  // useEffect(() => {
  //   console.log("uef textValue :>> ", textValue);
  //   updateIdQuery(textValue);
  // }, [textValue]);

  // const findId = debounce(updateIdQuery, 500);

  return (
    <div className={`project_input select ${generalClassName}  ${labelWrap ? "" : "labelWrap"}`} id={id}>
      {label && (
        <label htmlFor={props.name} style={{width: widthLabel}} className={props.autolabel ? "autolabel" : ""}>
          {/* {  typeof label === "string" ? t(label) : label  } */}
          {label}
        </label>
      )}
      <div
        style={{display: "flex", position: "relative", width: width, maxHeight: "100px"}}
        onMouseLeave={() => setTimeout(() => setShowOptions(false), 100)}
      >
        <input
          ref={inputRef}
          className={inputClassName}
          readOnly={props.readOnly || selectOnly}
          value={textValue}
          autoComplete="off"
          style={{width: "100%"}}
          onClick={openOptions}
          onChange={e => {
            setTextValue(e.target.value);
            updateIdQuery(e.target.value);
            // findId(e.target.value);
            delayedFilter(e.target.value);
            // makeEffect(e.target.value);
          }}
          disabled={disabled}
          onKeyDown={onOptionsListKeyDown}
          onMouseEnter={question ? onMouseEnter : null}
          onMouseLeave={question ? onMouseLeave : null}
        />
        {meta.error && meta.touched ? <div className="error">{meta.error}</div> : null}
        {errors[errorField] && meta.touched ? <div className="error">{errors[errorField]}</div> : null}
        {!props.readOnly && (
          <div
            className={`select__list_button ${disabled ? "disable" : ""}`}
            onClick={() => {
              if (disabled) return;
              if (!props.readOnly) {
                showAllOptions();
                setShowOptions(i => (i = !i));
              }
            }}
          >
            ▼
          </div>
        )}

        {showOptions && (
          <div
            className="select__list disable"
            ref={optionsListContainerRef}
            onKeyDown={onOptionsListKeyDown}
            tabIndex="0"
          >
            <ul ref={optionsListRef}>{optionList}</ul>
          </div>
        )}
        {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
      </div>
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  props: PropTypes.array,
  id: PropTypes.string,
  options: PropTypes.array,
  minFilterLength: PropTypes.number,
  errorField: PropTypes.string,
  disabled: PropTypes.bool,
};
