import {dateFormat, dateFullFormat} from "../../../utilities/dateFormat";

const kitTableDataService = allKits => {
  return (
    allKits?.map(
      (
        {createdAt, brandManager, manager, dateFrom, dateTo, name, status, skuNumber, samples, kitId, images},
        index,
      ) => {
        const nameServ = !brandManager?.firstName
          ? brandManager?.lastName
          : brandManager?.lastName + " " + brandManager?.firstName;
        const nameMngServ = !manager?.firstName ? manager?.lastName : manager?.lastName + " " + manager?.firstName;
        return {
          id: kitId,
          number: ++index,
          createdAt: dateFullFormat(createdAt),
          brandManager: nameServ,
          manager: nameMngServ || "-",
          dateFrom: dateFormat(dateFrom) || "-",
          dateTo: dateFormat(dateTo) || "-",
          name,
          status,
          skuNumber,
          factory: samples ? samples[0]?.factory?.factoryName : "",
          kitPhoto: images?.length > 0 ? "✔" : "✖",
        };
      },
    ) || []
  );
};

export default kitTableDataService;
