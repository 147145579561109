import {memo, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {fetchData} from "../../hook/axios.hook";
import useColumns from "../../hook/useColumns";
import AddIcon from "../../images/icons/8201370_add_plus_button_ui_new_icon.png";
import EditIcon from "../../images/icons/8725775_edit_icon.png";
import DeleteIcon from "../../images/icons/8726424_trash_alt_icon.png";
import DublicateIcon from "../../images/icons/11030095_copy_icon.png";
import ViewIcon from "../../images/icons/openEye.png";
import {
  clearNewKit,
  deleteKit,
  dublicateKit,
  getAllKits,
  getKit,
  selectAllKits,
  selectAllSamples,
  selectKit,
} from "../../slices/SampleFunctionalSlice";
import {setCurrentKitId, setCurrentSampleId, setModalData} from "../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../slices/SampleSlice/selectors";
import {getCurrentUser} from "../../slices/UserSlice";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import useSwal from "../../utilities/hooks/useSwal";
import useToaster from "../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../utilities/makeFilterManager/makeFilterManager";
import ReactTable from "../conteiners/ReactTable";
import {SAMPLES_FILTERS_STORAGE_KEY} from "../Filters/utils/constants";
import ModalForm from "../Modal/FormModal";
import {SampleListUpperColumns} from "../SamplesList/constants/columns";
import kitTableDataService from "../SamplesList/constants/kitTableDataService";
import KitPreview from "../SamplesList/previewModal/KitPreview";
import makeKitsFilters from "../SamplesList/SampleParts/SampleFilter/utils/makeKitsFilters";
import {TYPE_FORMS} from "../SamplesList/SamplesList";

const UpperKitBlock = ({
  setFormType,
  setShowModal,
  setTabValue,
  setTabs,
  setTabProps,
  setTabLabel,
  setCurrentSample,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const currUser = useSelector(getCurrentUser);
  const [kitPreviewModalShow, setKitPreviewModalShow] = useState(false);

  const allKits = useSelector(selectAllKits);
  const kit = useSelector(selectKit);
  const openedInEditKitId = kit?.kitId;
  const currentKitId = useSelector(selectCurrentKitId);
  const allSamples = useSelector(selectAllSamples);

  const SampleFilterManager = makeFilterManager(SAMPLES_FILTERS_STORAGE_KEY);
  const filters = useMemo(() => SampleFilterManager.getFilters() ?? {}, []);

  const kitFilters = makeKitsFilters(filters);
  const filterKitId = filters.kitId;

  useEffect(() => {
    dispatch(getAllKits(kitFilters));
  }, []);

  const [filteredKits, setFilteredKits] = useState(null);

  useEffect(() => {
    if (filterKitId) {
      setFilteredKits(
        [...allKits].sort((a, b) => {
          if (a.kitId === filterKitId) return -1;
          if (b.kitId === filterKitId) return 1;
          return 0;
        }),
      );
    }
  }, [allKits.length]);

  const upperTableColumns = useColumns(SampleListUpperColumns);
  const kitTableData = kitTableDataService(filteredKits ?? allKits);

  const [currentKit, setCurrentKit] = useState(null);

  useEffect(() => {
    setCurrentKit(allKits?.find(kit => kit.kitId === currentKitId) ?? null);
  }, [filters?.kitId, allKits?.length]);

  useEffect(() => {
    if (!currentKitId) {
      setCurrentKit(null);
    }
  }, [currentKitId]);

  const isOwn = currentKit?.brandManager?.userId === currUser?.userId;

  const onCreateKit = async () => {
    // await dispatch(deselectKit());
    // dispatch(deselectKitImages());

    // dispatch(deselectSample());
    // dispatch(deselectSampleImages());

    setCurrentKit(null);
    dispatch(clearNewKit());
    setFormType("CREATE_KIT");
    // setShowModal(true);
  };

  function editKitFunc({isKitEditOpen}) {
    if (currentKit?.status === "Under Report") {
      Swal.fire({
        title: "",
        text: t("forbidd edit kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentKit?.status === "On Stock" && isOwn) {
      if (isKitEditOpen) {
        let kitUpdateTabValue;
        setTabs(prev => {
          const updateKitTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.UPDATE_KIT);
          updateKitTab.label = `${t("edit k")} №${currentKit?.skuNumber ?? ""}`;
          kitUpdateTabValue = prev.indexOf(updateKitTab);
          return prev;
        });
        dispatch(setModalData({typeForm: TYPE_FORMS.UPDATE_KIT, formData: {}}));
        setTabValue(kitUpdateTabValue < 0 ? 0 : kitUpdateTabValue);
      } else {
        setFormType("UPDATE_KIT");
        setTabLabel(`${t("edit k")} №${currentKit?.skuNumber ?? ""}`);
      }
      // !isKitEditOpen && dispatch(deselectSample());
      // dispatch(deselectSampleImages());
      dispatch(getKit({kitId: currentKit.kitId, typeForm: TYPE_FORMS.UPDATE_KIT})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentKit(res.payload);
        }
      });
      setShowModal(true);
    } else {
      Swal.fire({
        title: "",
        text: t("forbid edit not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  }

  const onEditKit = () => {
    if (currentKitId === openedInEditKitId) {
      Toaster.error("The kit has already been opened for editing");
      return;
    }
    if (openedInEditKitId && currentKitId !== openedInEditKitId) {
      SWAL.confirm({
        title: "You can open only one kit form editting",
        confirmFunc: () => {
          editKitFunc({isKitEditOpen: true});
        },
      });
    } else {
      editKitFunc({isKitEditOpen: false});
    }
  };

  const onDeleteKit = () => {
    if (currentKit?.status === "Under Report") {
      Swal.fire({
        title: "",
        text: t("forbidd kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbid not own kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else if (currentKit?.samples?.length > 0) {
      Swal.fire({
        title: "",
        text: t("contains ins"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else if (currentKit?.status === "On Stock" && isOwn) {
      Swal.fire({
        title: "",
        text: t("del kit"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteKit(currentKit.kitId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Kit deleted");
              dispatch(setCurrentKitId(null));
              setCurrentKit(null);
              const samplesFilters = SampleFilterManager.getFilters() ?? {};
              const isOnlyKitIdFilter = Object.keys(samplesFilters).length === 1 && Boolean(samplesFilters.kitId);

              isOnlyKitIdFilter
                ? SampleFilterManager.clearFilters()
                : SampleFilterManager.setFilters({...samplesFilters, kitId: null});
            }
          });
        }
      });
    }
  };

  const onDublicateKit = () => {
    if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbit to dubl kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else {
      Swal.fire({
        title: "",
        text: t("kit dubl"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(dublicateKit(currentKit.kitId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Kit dublicated");
            }
          });
        }
      });
    }
  };

  const onKitSelect = id => {
    // setCurrentSample(null);
    dispatch(setCurrentSampleId(null));
    const filters = SampleFilterManager.getFilters();
    if ((currentKit && id === currentKit.kitId) || id === currentKitId) {
      dispatch(setCurrentKitId(null));

      setCurrentKit(null);

      SampleFilterManager.setFilters({...filters, kitId: null});
      return;
    }

    dispatch(setCurrentKitId(id));
    const currKit = allKits?.find(item => item.kitId === id);
    SampleFilterManager.setFilters({...filters, kitId: id});
    setCurrentKit(currKit);
  };

  const onRowDoubleClick = row => {
    fetchData(`/kits/get/${row.id}`, "get").then(res => {
      if (res) setCurrentKit(res);
    });
    setKitPreviewModalShow(true);
  };

  function onViewKit() {
    let kitViewTabValue;
    setTabs(prev => {
      const viewKitTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.VIEW_KIT);
      if (viewKitTab) {
        viewKitTab.label = `${t("view k")} №${currentKit?.skuNumber ?? ""}`;
      }
      kitViewTabValue = prev.indexOf(viewKitTab);
      return kitViewTabValue > 0
        ? prev.map(tab => {
            if (tab.props.typeForm === TYPE_FORMS.VIEW_KIT) {
              tab.props.kitId = currentKit?.kitId;
            }
            return tab;
          })
        : prev;
    });

    if (kitViewTabValue && kitViewTabValue > 0) {
      setTabValue(kitViewTabValue);
    } else {
      setTabLabel(`${t("view k")} №${currentKit?.skuNumber ?? ""}`);
      setTabProps({kit: {...currentKit, samples: allSamples}, kitId: currentKit?.kitId});
      setFormType(TYPE_FORMS.VIEW_KIT);
    }
  }

  const areChangeKitBtnsDisabled = !currentKitId || currUser?.role?.roleId === 3;
  const upperBtnSize = 2.2;

  return (
    <div className="upper_part_s">
      <ReactTable
        defaultData={kitTableData}
        columns={upperTableColumns}
        onSelect={onKitSelect}
        current={currentKitId || null}
        className={"scrollX"}
        onRowDoubleClick={onRowDoubleClick}
        style={{height: "12em"}}
        tableClassName="tableCenterText"
      />
      <div className="fcCol">
        <div className="tac">{t("Kit")}</div>
        <div className="upper_buttons">
          <ImageButton
            src={AddIcon}
            tooltipMessage="add kit btn"
            className="upper_btn_item"
            onClick={() => {
              onCreateKit();
            }}
            disabled={currUser?.role?.roleId === 3}
            size={upperBtnSize}
          />
          <ImageButton
            src={EditIcon}
            tooltipMessage={`${t("edit kit btn")} (${t("Only one component can be edited at the same time")})`}
            className="upper_btn_item"
            onClick={() => {
              onEditKit();
            }}
            disabled={areChangeKitBtnsDisabled}
            size={upperBtnSize}
          />
          <ImageButton
            src={ViewIcon}
            tooltipMessage="view kit btn"
            tooltipPlacement="left"
            className="upper_btn_item"
            onClick={onViewKit}
            disabled={areChangeKitBtnsDisabled}
            size={upperBtnSize}
          />
          <ImageButton
            src={DublicateIcon}
            tooltipMessage="dubl kit btn"
            tooltipPlacement="bottom"
            className="upper_btn_item"
            onClick={() => {
              onDublicateKit();
            }}
            disabled={areChangeKitBtnsDisabled}
            size={upperBtnSize}
          />
          <ImageButton
            src={DeleteIcon}
            tooltipMessage="del kit btn"
            tooltipPlacement="bottom"
            className="upper_btn_item"
            onClick={() => {
              onDeleteKit();
            }}
            disabled={areChangeKitBtnsDisabled}
            size={upperBtnSize}
          />
        </div>
      </div>

      <ModalForm
        closable={true}
        active={kitPreviewModalShow}
        Component={KitPreview}
        setModalActive={setKitPreviewModalShow}
        label={t("sett") + " № " + currentKit?.skuNumber || ""}
        kit={currentKit}
        // typeForm={formType}
        className="uni_modal"
      />
    </div>
  );
};

export default memo(UpperKitBlock);
