import React from "react";

const Divider = () => {
  return (
    <div
      className="w100"
      style={{border: "1px solid #A3B9D9", height: 0, marginTop: "10px", marginBottom: "10px"}}
    ></div>
  );
};

export default Divider;
