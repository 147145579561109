import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import cross from "../../../images/icons/cross.png";
import saveIcon from "../../../images/icons/save.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import ReactTable from "../../conteiners/ReactTable";
import {TextInput} from "../../Inputs";

const GuideCard = ({
  label,
  fieldName,
  columns,
  tableData,
  onTableRowSelect = () => {},
  onAddBtnClick = () => {},
  onDeleteBtnClick = () => {},
  current = null,
  noButtons = false,
}) => {
  const {t} = useTranslation();

  return (
    <div className="guide_size">
      <div className="fdr">
        <TextInput label={t(label) + ":"} name={fieldName} />
        {!noButtons && (
          <>
            <ImageButton
              src={saveIcon}
              alt="add or update"
              className="btn__upload"
              width={1.6}
              height={1}
              onClick={onAddBtnClick}
            />
            <ImageButton
              src={cross}
              alt="delete"
              className="btn__upload"
              width={1.2}
              height={1.2}
              onClick={onDeleteBtnClick}
            />
          </>
        )}
      </div>

      <ReactTable
        defaultData={tableData ?? []}
        columns={columns}
        onSelect={onTableRowSelect}
        current={current}
        // defaultValue={current}
        className={"scrollX"}
        style={{height: "100%", minHeight: "6em"}}
      />
    </div>
  );
};

GuideCard.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array,
  onTableRowSelect: PropTypes.func,
  onAddBtnClick: PropTypes.func,
  onDeleteBtnClick: PropTypes.func,
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  noButtons: PropTypes.bool,
};

export default GuideCard;
