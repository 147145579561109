import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {clearCurrent} from "../../../slices/SampleSlice/SampleSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ModalForm from "../../Modal/FormModal";
import SampleGuideModal from "../SampleModals/SampleGuideModal/SampleGuideModal";
import SampleRegistrManager from "../SampleModals/SampleRegistrManager";

import "../samples.scss";

const SampleFuncButtons = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [managerRegistrModalActive, setManagerRegistrModalActive] = useState(false);
  const [guidesModalActive, setGuidesModalActive] = useState(false);

  function onGuideModalClose() {
    dispatch(clearCurrent());
  }

  return (
    <div className="lower_cont">
      <div className="button_list">
        <FunctionButton
          name="guides"
          onClick={() => {
            setGuidesModalActive(true);
          }}
        />
        <FunctionButton
          name="mregist"
          autoWidth
          onClick={() => {
            setManagerRegistrModalActive(true);
          }}
        />
      </div>

      <ModalForm
        Component={SampleRegistrManager}
        label={t("creat mng")}
        active={managerRegistrModalActive}
        setModalActive={setManagerRegistrModalActive}
        className="modal_size"
        modalInnerWrapperClassName="m_conf"
        closable={true}
      />

      <ModalForm
        closable={true}
        onModalClose={onGuideModalClose}
        Component={SampleGuideModal}
        label={t("s guide")}
        active={guidesModalActive}
        setModalActive={setGuidesModalActive}
        className="uni_modal"
      />
    </div>
  );
};

export default SampleFuncButtons;
