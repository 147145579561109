import Divider from "../../utilities/Divider/Divider";
import withDynamicPageContainer from "../DynamicPageContainer/withDynamicPageContainer";
import SampleFilter from "../SamplesList/SampleParts/SampleFilter/SampleFilter";
import SampleFuncButtons from "../SamplesList/SampleParts/SampleFuncButtons";

import NoKitSampleBlock from "./NoKitSampleBlock/NoKitSampleBlock";
import useSamplesListNew from "./useSamplesListNew";

export const NO_KIT_SAMPLE_LIST_FILTERS_STORAGE_KEY = "noKitSampleListFilters";

const SamplesListNew = ({setTabs, setTabValue}) => {
  const {
    currUser,
    currentSample,
    setCurrentSample,
    setTabLabel,
    setTabProps,
    formType,
    setFormType,
    onFilterAdditional,
    onFilterDelAdditional,
  } = useSamplesListNew({setTabs, setTabValue});

  return (
    <div id={"context-menu-container"} className="form_wrapper hmax100 fGrow " style={{justifyContent: "flex-start"}}>
      <div>
        <SampleFilter
          storageKey={NO_KIT_SAMPLE_LIST_FILTERS_STORAGE_KEY}
          onFilterAdditional={onFilterAdditional}
          onFilterDelAdditional={onFilterDelAdditional}
          isFree={true}
        />
        <Divider />
      </div>
      <NoKitSampleBlock
        setShowModal={() => {}}
        setFormType={setFormType}
        formType={formType}
        setTabValue={setTabValue}
        setTabs={setTabs}
        setTabProps={setTabProps}
        setTabLabel={setTabLabel}
        currentSample={currentSample}
        setCurrentSample={setCurrentSample}
      />

      {currUser?.role?.roleId === 3 && (
        <div>
          <Divider />
          <SampleFuncButtons />
        </div>
      )}
    </div>
  );
};

export default withDynamicPageContainer({Component: SamplesListNew, title: "sapmle reg"});
