import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";

import useColumns from "../../hook/useColumns";
import AddIcon from "../../images/icons/8201370_add_plus_button_ui_new_icon.png";
import EditIcon from "../../images/icons/8725775_edit_icon.png";
import DeleteIcon from "../../images/icons/8726424_trash_alt_icon.png";
import DublicateIcon from "../../images/icons/11030095_copy_icon.png";
import ViewIcon from "../../images/icons/openEye.png";
import UnlinkIcon from "../../images/icons/unlink.png";
import {
  clearAllSamples,
  clearNewSample,
  deleteSample,
  deselectKitImages,
  dublicateSample,
  getAllSamples,
  getSample,
  selectAllSamples,
  selectSample,
  selectSamplesLoading,
  selectSamplesPage,
  selectSamplesTotalPageas,
} from "../../slices/SampleFunctionalSlice";
import {setCurrentSampleId, setModalData} from "../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId, selectCurrentSampleId} from "../../slices/SampleSlice/selectors";
import {getCurrentUser} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import ImageButton from "../../utilities/Buttons/ImageBtn";
import useSwal from "../../utilities/hooks/useSwal";
import useToaster from "../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../utilities/makeFilterManager/makeFilterManager";
import ReactTable from "../conteiners/ReactTable";
import {SAMPLES_FILTERS_STORAGE_KEY} from "../Filters/utils/constants";
import {TextInput} from "../Inputs";
import ModalForm from "../Modal/FormModal";
import {SampleListLowerColumns} from "../SamplesList/constants/columns";
import samplesTableDataService from "../SamplesList/constants/samplesTableDataService";
import SamplePreview from "../SamplesList/previewModal/SamplePreview";
import makeSamplesFilters from "../SamplesList/SampleParts/SampleFilter/utils/makeSamplesFilters";
import {TYPE_FORMS} from "../SamplesList/SamplesList";
import Spinner from "../spinner/Spinner";

const LowerKitBlock = ({setFormType, setShowModal, setTabValue, setTabs, setTabProps, setTabLabel}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const allSamples = useSelector(selectAllSamples),
    totalPages = useSelector(selectSamplesTotalPageas),
    currPage = useSelector(selectSamplesPage);

  const currUser = useSelector(getCurrentUser);
  const currentKitId = useSelector(selectCurrentKitId);
  const sample = useSelector(selectSample);
  const openedInEditSampleId = sample?.sampleId;
  const areSamplesLoading = useSelector(selectSamplesLoading);
  const currentSampleId = useSelector(selectCurrentSampleId);
  const currentSample = allSamples?.find(sample => sample.sampleId === currentSampleId) ?? null;

  const SampleFilterManager = makeFilterManager(SAMPLES_FILTERS_STORAGE_KEY);
  const filters = SampleFilterManager.getFilters() ?? {};
  const samplesFilters = makeSamplesFilters(filters);

  const lowerTableColumns = useColumns(SampleListLowerColumns);

  const [samplePrewiewModalShow, setSamplePrewiewModalShow] = useState(false);

  const instanceTableData = samplesTableDataService(allSamples);

  useEffect(() => {
    if (currentKitId) {
      dispatch(getAllSamples({kitId: currentKitId, page: 1}));
    } else {
      dispatch(clearAllSamples());
    }
  }, [currentKitId]);

  const isOwn = currentSample?.brandManager?.userId === currUser?.userId;

  const onCreateSample = async () => {
    dispatch(clearNewSample());
    setFormType(TYPE_FORMS.CREATE_KIT_SAMPLES);
  };

  function onEditSampleFunc({isSampleEditOpen}) {
    if (currentSample?.status === "Under Report") {
      Swal.fire({
        title: "",
        text: t("forbidd edit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.status === "On Stock" && isOwn) {
      if (isSampleEditOpen) {
        let sampleUpdateTabValue;
        setTabs(prev => {
          const updateSampleTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.EDIT_KIT_SAMPLES);
          if (!updateSampleTab) return;
          updateSampleTab.label = `${t("edit k s")} №${currentSample?.skuNumber ?? ""}`;
          sampleUpdateTabValue = prev.indexOf(updateSampleTab);
          return prev;
        });
        setTabValue(sampleUpdateTabValue < 0 ? 0 : sampleUpdateTabValue);
        dispatch(setModalData({typeForm: TYPE_FORMS.EDIT_KIT_SAMPLES, formData: {}}));
      } else {
        setFormType(TYPE_FORMS.EDIT_KIT_SAMPLES);
        setTabLabel(`${t("edit k s")} №${currentSample?.skuNumber ?? ""}`);
      }
      dispatch(deselectKitImages());

      dispatch(getSample(currentSample.sampleId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(setCurrentSampleId(res.payload?.sampleId));
        }
      });

      setShowModal(true);
    } else {
      Swal.fire({
        title: "",
        text: t("forbid edit not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  }

  const onEditSample = () => {
    if (currentSample?.sampleId === openedInEditSampleId) {
      Toaster.error("The sample has already been opened for editing");
      return;
    }
    if (openedInEditSampleId && currentSample?.sampleId !== openedInEditSampleId) {
      SWAL.confirm({
        title: "You can open only one sample form editting",
        confirmFunc: () => {
          onEditSampleFunc({isSampleEditOpen: true});
        },
      });
    } else {
      onEditSampleFunc({isSampleEditOpen: false});
    }
  };

  const onInstanceDelete = () => {
    if (currentSample?.status === "Under Report") {
      Swal.fire({
        title: "",
        text: t("forbidd"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.status === "On Stock" && isOwn) {
      Swal.fire({
        title: "",
        text: t("del sample"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteSample(currentSample.sampleId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Sample deleted");
            }
          });
          // setCurrentSample(null);
          dispatch(setCurrentSampleId(null));
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("forbid not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  };

  const onDublicateSample = () => {
    if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbit to dubl"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else {
      Swal.fire({
        title: "",
        text: t("sampl dubl"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(dublicateSample(currentSample.sampleId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Sample dublicated");
            }
          });
        }
      });
    }
  };

  const onInstanceSelect = id => {
    if (currentSample && id === currentSample.sampleId) {
      // setCurrentSample(null);
      dispatch(setCurrentSampleId(null));
      return;
    }

    const currSamp = allSamples?.find(item => item.sampleId === id);
    // setCurrentSample(currSamp);
    dispatch(setCurrentSampleId(currSamp.sampleId));
  };

  const onRowDoubleClick = row => {
    // console.log(row.id);
    const currSamp = allSamples?.find(item => item.sampleId === row.id);
    // setCurrentSample(currSamp);
    dispatch(setCurrentSampleId(currSamp.sampleId));
    setSamplePrewiewModalShow(true);
  };

  const initialValues = {};

  const pagg = async () => {
    if (totalPages === currPage || totalPages === 1) return;
    dispatch(getAllSamples({...samplesFilters, page: currPage + 1}));
  };
  const sampleControlBtnsSize = 2.2;

  function onViewSample() {
    let sampleViewTabValue;
    setTabs(prev => {
      const viewSampleTab = prev.find(tab => tab.props.typeForm === TYPE_FORMS.VIEW_KIT_SAMPLES);
      if (viewSampleTab) {
        viewSampleTab.label = `${t("view k s")} №${currentSample?.skuNumber ?? ""}`;
      }
      sampleViewTabValue = prev.indexOf(viewSampleTab);
      return sampleViewTabValue > 0
        ? prev.map(tab => {
            if (tab.props.typeForm === TYPE_FORMS.VIEW_KIT_SAMPLES) {
              tab.props.sampleId = currentSample?.sampleId;
            }
            return tab;
          })
        : prev;
    });
    if (sampleViewTabValue && sampleViewTabValue > 0) {
      setTabValue(sampleViewTabValue);
    } else {
      setTabLabel(`${t("view k s")} №${currentSample?.skuNumber ?? ""}`);
      setTabProps({sampleId: currentSample?.sampleId});
      setFormType(TYPE_FORMS.VIEW_KIT_SAMPLES);
    }
  }

  function onUnlinkSample() {}

  return (
    <div className="fGrow fShrink fcCol jcsb overflowAuto">
      {areSamplesLoading && <Spinner />}
      <ReactTable
        defaultData={instanceTableData}
        columns={lowerTableColumns}
        onSelect={onInstanceSelect}
        current={currentSampleId}
        className={"scrollX fGrow fShrink"}
        tableClassName="tableCenterText"
        style={{
          marginBottom: "10px",
          maxHeight: "unset",
          height: "79%",
        }}
        onRowDoubleClick={onRowDoubleClick}
        onPaginate={pagg}
        paginatable={true}
      />

      <div className="fdr jcsb mt">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({})}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          <Form>
            <div className="inv">
              <TextInput label={t("inv date")} name="invDate" readOnly labelStyles={{width: "12em"}} />
              <FunctionButton name="inv" />
            </div>
          </Form>
        </Formik>
        <div className="fc gap aic mb">
          <div className="tac">{t("Sample")}</div>
          <div className="middle_buttons">
            <ImageButton
              src={AddIcon}
              tooltipMessage="add sample btn"
              onClick={() => {
                onCreateSample();
              }}
              disabled={currUser?.role?.roleId === 3 || !currentKitId}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={EditIcon}
              tooltipMessage={`${t("edit sample btn")} (${t("Only one sample can be edited at the same time")})`}
              onClick={() => {
                onEditSample();
              }}
              disabled={!currentSample || currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={ViewIcon}
              tooltipMessage="view sample btn"
              className="upper_btn_item"
              onClick={onViewSample}
              disabled={!currentSample || currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={DublicateIcon}
              tooltipMessage="dubl sample btn"
              onClick={() => {
                onDublicateSample();
              }}
              disabled={!currentSample || currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={UnlinkIcon}
              tooltipMessage="unlink sample btn"
              onClick={() => {
                onUnlinkSample();
              }}
              disabled={!currentSample || currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={DeleteIcon}
              tooltipMessage="del sample btn"
              onClick={() => {
                onInstanceDelete();
              }}
              disabled={!currentSample || currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
          </div>
        </div>
      </div>
      <ModalForm
        closable={true}
        active={samplePrewiewModalShow}
        Component={SamplePreview}
        setModalActive={setSamplePrewiewModalShow}
        label={"SKU №" + currentSample?.skuNumber || ""}
        sample={currentSample}
      />
    </div>
  );
};
export default LowerKitBlock;
