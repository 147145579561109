import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import useContextMenu from "../../hook/useContextMenu";
import {getAllKits} from "../../slices/SampleFunctionalSlice";
import {getCurrentUser} from "../../slices/UserSlice";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";
import Divider from "../../utilities/Divider/Divider";
import SampleFilter from "../Filters/SampleFilter";
import {KIT_SAMPLES_FILTERS_STORAGE_KEY} from "../Filters/utils/constants";
import TabsContainer from "../NEW-tabs/TabsContainer";
import SampleFuncButtons from "../SamplesList/SampleParts/SampleFuncButtons";

import KitTable from "./kitBlock/KitTable";
import KitSamplesTable from "./sampleBlock/KitSamplesTable";

const KitsList = () => {
  const dispatch = useDispatch();
  const currUser = useSelector(getCurrentUser);

  const fetchSamples = values => {
    if (!values) {
      dispatch(getAllKits());
      return;
    }
    const filters = checkEmptyFields(values);
    dispatch(getAllKits(filters));
  };

  return (
    <TabsContainer label={"kit reg"} closableTabs={true}>
      <div id={"context-menu-container"} className="form_wrapper hmax100 fGrow" style={{justifyContent: "flex-start"}}>
        <div>
          <SampleFilter
            fetchData={fetchSamples}
            removeFilter={() => {
              fetchSamples(null);
            }}
            storageKey={KIT_SAMPLES_FILTERS_STORAGE_KEY}
          />
          <Divider />
        </div>

        <KitListTables />

        {currUser?.role?.roleId === 3 && (
          <div>
            <Divider />
            <SampleFuncButtons />
          </div>
        )}
      </div>
    </TabsContainer>
  );
};

const KitListTables = () => {
  const [currentSample, setCurrentSample] = useState(null);
  const [contextMenuType, setContextMenuType] = useState("KIT");
  const {menuVisible, handleContextMenu, menuPosition} = useContextMenu();
  return (
    <>
      <KitTable
        currentSample={currentSample}
        setCurrentSample={setCurrentSample}
        menuVisible={menuVisible}
        menuPosition={menuPosition}
        handleContextMenu={e => {
          handleContextMenu(e);
          setContextMenuType("KIT");
        }}
        contextMenuType={contextMenuType}
      />
      <KitSamplesTable
        currentSample={currentSample}
        setCurrentSample={setCurrentSample}
        menuVisible={menuVisible}
        menuPosition={menuPosition}
        handleContextMenu={e => {
          handleContextMenu(e);
          setContextMenuType("SAMPLE");
        }}
        contextMenuType={contextMenuType}
      />
    </>
  );
};

export default KitsList;
